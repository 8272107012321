export default {
  submit: '提交',
  reset: '重置',
  validation: {
    required: '此项目不能为空'
  },
  routes: {
    email_confirmation: {
      title: '邮箱验证',
      errors: {
        already_confirmed: '已验证过的邮箱',
        expired: '邮箱验证请求已过期。请尝试重新发送验证邮件',
        invalid: '无法通过当前验证请求'
      }
    },
    password_reset: {
      title: '重置密码',
      new_password: '密码',
      confirm_password: '确认密码',
      success: '密码重置成功',
      errors: {
        password_length: '密码长度要求为 {min} ~ {max} 字符',
        password_not_match: '两次输入密码不一致',
        token_error: {
          invalid: '无法处理当前密码重置请求',
          expired: '密码重置请求已过期。请重新开始再试一次'
        }
      }
    }
  },
  gv_cloud_error: {
    code_3001: '权限验证失败，无法处理当前请求',
    code_3003: '此账号没有密码，请使用第三方社交账号'
  },
  general_api_error: '检测到错误。请稍后重试'
};