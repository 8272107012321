export default {
  submit: '提出',
  reset: 'クリア',
  validation: {
    required: '入力してください'
  },
  routes: {
    email_confirmation: {
      title: 'メールアドレス確認',
      errors: {
        already_confirmed: '既に確認済みメールアドレス',
        expired: 'このメール確認リクエストの期限が切れました。お手数ですがもう一度確認メールの送信からお試しください。',
        invalid: 'この確認リクエストは処理できません'
      }
    },
    password_reset: {
      title: 'パスワード再設定',
      new_password: 'パスワード',
      confirm_password: 'パスワード確認',
      success: 'パスワードが変更されました',
      errors: {
        password_length: 'パスワードの長さ条件は {min} ~ {max} 文字です',
        password_not_match: '２回入力したパスワードが一致しません',
        token_error: {
          invalid: 'このパスワード変更のリクエストは処理できません',
          expired: 'このパスワード変更のリクエスト期限が切れました。お手数ですがもう一度最初からお試しください'
        }
      }
    }
  },
  gv_cloud_error: {
    code_3001: '認証が失敗したため、このリクエストは処理できません',
    code_3003: 'このアカウントにはパスワードが必要ありません。ソーシャルアカウント経由でログインしてください'
  },
  general_api_error: 'エラーが発生しました。しばらくしてからもう一度お試しください'
};