export default {
  submit: 'Submit',
  reset: 'Reset',
  validation: {
    required: 'The content should not be blank'
  },
  routes: {
    email_confirmation: {
      title: 'Email Confirmation',
      errors: {
        already_confirmed: 'Email confirmation already completed.',
        expired: 'Email confirmation period already expired. Please try sending confirmation email again.',
        invalid: 'This confirmation request cannot be fulfilled.'
      }
    },
    password_reset: {
      title: 'Reset Password',
      new_password: 'New password',
      confirm_password: 'Confirm password',
      success: 'Your password has been successfully updated.',
      errors: {
        password_length: 'The password length should be between {min} ~ {max}.',
        password_not_match: 'The passwords do not match.',
        token_error: {
          invalid: 'This password reset request cannot be fulfilled.',
          expired: 'This password reset request has expired. Please start by sending reset email again.'
        }
      }
    }
  },
  gv_cloud_error: {
    code_3001: 'A valid token is required before continuing.',
    code_3003: 'This account doesn\'t require a password. Please use your auth provider.'
  },
  general_api_error: 'Something wrong happened. Please retry later.'
};