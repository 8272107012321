import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import apiClient from '@/api/client';
import en from './lang/en';
import enLocale from 'element-ui/lib/locale/lang/en';
import ElementLocale from 'element-ui/lib/locale';
// import enCountry from './lang/country/en';

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  ja: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
  'zh-CN': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  }
};

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      ...enLocale,
      ...en
      // ...enCountry
    }
  },
  dateTimeFormats
});

ElementLocale.i18n((key, value) => i18n.t(key, value))

const loadedLanguages = ['en']; // our default language that is prelaoded

_setDefaultLanguage();

function _setDefaultLanguage() {
  const defaultLang = _getDefaultLanguage();
  const defaultLangName = defaultLang.name;

  if (defaultLangName !== 'en') {
    i18n.setLocaleMessage(defaultLangName, defaultLang.module);
    loadedLanguages.push(defaultLangName);
  }

  _setI18nLanguage(defaultLangName);
}

function _getDefaultLanguage() {
  let lang =
    window.localStorage.getItem('language') ||
    window.navigator.language ||
    'en';
  let langModule;

  try {
    langModule = [
      require(`./lang/${lang}`),
      require(`element-ui/lib/locale/lang/${lang}`),
      // require(`./lang/country/${lang}`)
    ];
  } catch (e) {
    lang = 'en';
    langModule = [
      require(`./lang/${lang}`),
      require(`element-ui/lib/locale/lang/${lang}`)
      // require(`./lang/country/${lang}`)
    ];
  }

  return {
    name: lang,
    module: langModule.reduce((acc, cur) => ({
      default: {
        ...acc.default,
        ...cur.default
      }
    })).default
  };
}

function _setI18nLanguage(lang) {
  i18n.locale = lang;

  try {
    // apiClient.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    window.localStorage.setItem('language', lang);
  } catch (e) {
    console.log(e);
  }

  return lang;
}

export function loadLanguageAsync(lang) {
  if (!lang) {
    return Promise.reject();
  }

  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return Promise.all([
        import(`@/i18n/lang/${lang}`),
        import(`element-ui/lib/locale/lang/${lang}`)
        // import(`@/i18n/lang/country/${lang}`)
      ]).then(msgs => {
        i18n.setLocaleMessage(
          lang,
          msgs.reduce((acc, cur) => ({
            default: {
              ...acc.default,
              ...cur.default
            }
          })).default
        );
        loadedLanguages.push(lang);
        return _setI18nLanguage(lang);
      });
    }
    return Promise.resolve(_setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}
