<template>
  <div id="app">
    <router-view ref="root"></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
html {
  font-family: 'Open Sans', 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue',
    'Hiragino Sans GB', 'WenQuanYi Micro Hei', Arial, sans-serif;
  font-size: 62.5%;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  &[lang='ja'] {
    font-family: 'Open Sans', 'Gotham', 'Hiragino Kaku Gothic Pro W3',
      'ヒラギノ角ゴ Pro W3', 'メイリオ', 'Meiryo', Arial, sans-serif;
  }
  &.fixed {
    overflow: hidden;
  }
}

body {
  height: 100%;
  font-size: 1.6rem;
  line-height: 1.5;
  background-color: #f7f8f9;

  &.o-transparent {
    background-color: transparent;
  }

  &.fixed {
    overflow: hidden;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  height: auto;
  max-width: 100%;
}

#app {
  min-height: 100%;
}

.no-record {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  color: #ccc;
  font-size: 1.2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.content {
  padding: 24px;
}

.button-wrapper {
  text-align: right;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-data {
  text-align: center;
}

input[readonly] {
  user-select: none;
}

// fix ios click event
body.touch {
  cursor: pointer;
}
</style>
