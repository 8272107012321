import Vue from 'vue';
import VueRouter from 'vue-router';
import { i18n, loadLanguageAsync } from '@/i18n';

const LOCALE_ALIASES = require('@/config/locale_aliases.json');

Vue.use(VueRouter);

const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/pages/NotFound.vue');
const Welcome = () => import(/* webpackChunkName: "home" */ '@/pages/Welcome.vue');
const EmailConfirmation = () => import(/* webpackChunkName: "home" */ '@/pages/EmailConfirmation.vue');
const PasswordReset = () => import(/* webpackChunkName: "home" */ '@/pages/PasswordReset.vue');
const Healthcheck = () => import(/* webpackChunkName: "healthcheck" */ '@/pages/Healthcheck.vue');
const Links = () => import(/* webpackChunkName: "links" */ '@/pages/Links.vue');

let publicRoutes = [
  {
    name: 'welcome',
    path: '/welcome',
    component: Welcome
  },
  {
    name: 'email_confirmation',
    path: '/email/confirmation',
    component: EmailConfirmation
  },
  {
    name: 'password_reset',
    path: '/password/reset',
    component: PasswordReset
  },
  {
    name: 'links',
    path: '/links',
    component: Links
  }
];

let authRoutes = [

];

authRoutes.forEach(r => {
  r.meta = {
    requiresAuth: true
  };
});

let routes = publicRoutes.concat(authRoutes);

const redirectRoutes = routes.map(route => {
  return {
    path: route.path,
    redirect: to => {
      return {
        name: route.name,
        params: Object.assign({ lang: i18n.locale }, to.params),
        hash: to.hash,
        query: to.query
      };
    }
  };
});

routes.forEach(route => {
  route.path = '/:lang' + route.path;
});

routes = routes.concat(redirectRoutes);

routes.push({
  name: 'healthcheck',
  path: '/healthcheck',
  component: Healthcheck
});

routes.push(
  {
    name: 'home',
    path: '/:lang',
    component: Welcome,
    meta: { requiresAuth: false }
  },
  { path: '/', redirect: { name: 'home' } }
);

routes.push({ name: '404', path: '*', component: NotFound });

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!store.getters['auth/isLoggedIn']) {
//       Msg.warning(i18n.t('routes.login.requireLogin'));
//       next({
//         name: 'login',
//         params: { lang: i18n.locale },
//         query: { redirect: to.fullPath },
//         replace: true
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;

  loadLanguageAsync(lang)
    .then(() => next())
    .catch(() => {
      next({
        name: to.name,
        params: Object.assign(to.params, {
          lang: LOCALE_ALIASES[lang] ? LOCALE_ALIASES[lang] : i18n.locale
        }),
        hash: to.hash,
        query: to.query,
        replace: true
      });
    });
});

export default router;